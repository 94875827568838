import { HomePage } from './pages/HomePage/HomePage';
import { GlobalStyle } from './styles';

export const App = () => {
    return (
        <>
            <GlobalStyle />
            <HomePage />
        </>
    );
}