import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import zigzagImg from '../../assets/zigzag.svg';
import lineImg from '../../assets/line.svg';
import lineLeftImg from '../../assets/line-left.svg';
import topLineVerticalImg from '../../assets/top-lines-vertical.svg';
import bottomLineVerticalImg from '../../assets/bottom-lines-vertical.svg';
import bottomLineHorizontalImg from '../../assets/bottom-lines-horizontal.svg';
import backgroundColorImg from '../../assets/background-color.svg';
import mobileLineVerticalImg from '../../assets/mobile-lines-vertical.svg';
import mobileLineHorizontalImg from '../../assets/mobile-lines-horizontal.svg';

export const H1 = styled.h1`
    padding: 0;
    margin: 0 0 32px 0;
    color: #0A2540;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 76px;
    &:before {
        position: absolute;
        content: url(${lineLeftImg});
        margin-left: -39px;
    }
    &:after {
        position: absolute;
        content: url(${zigzagImg});
        width: 188px;
        height: 91px;
        transform: rotate(-10deg);
    }

    //Mobile
    ${down('lg')} {
        font-size: 30px;
        line-height: 36px;
      
        &:before {
            position: absolute;
            content: url(${lineLeftImg});
            margin-left: -22px;
            margin-top: -26px;
            transform: scale(0.8);
        }
        &:after {
            position: absolute;
            content: url(${zigzagImg});
            width: 120px;
            height: 60px;
            margin-left: -36px;
            margin-top: -4px;
            transform: rotate(-4deg);
        }
    }
`;

export const H2 = styled.h2`
    position: relative;
    margin: 0 0 72px;
    color: #0A2540;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    &:after {
        position: absolute;
        left: 132px;
        bottom: -24px;
        content: url(${lineImg});
        width: 231px;
        height: 32px;
    }
    
    //Mobile
    ${down('lg')} {
        margin: 0 0 48px;
        font-size: 30px;
        line-height: 36px;
        &:after {
            position: absolute;
            left: 72px;
            bottom: -6px;
            content: url(${lineImg});
            width: 135px;
            height: 20px;
        }
    }
`;

export const H3 = styled.h3`
    margin: 0 0 16px 0;
    color: #0A2540;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.56px;
    text-transform: uppercase;

    //Mobile
    ${down('lg')} {
        margin: 0 0 8px 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.48px;
    }
`;

export const Paragraph = styled.p`
    margin: 0 0 48px 0;
    color: #586179;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.24px;
    > a {
        color: #586179;
    }

    //Mobile
    ${down('lg')} {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.18px;
        > span {
            display: block;
            margin-top: 12px;
        }
    }
`;

export const Button = styled.button<{ $size: 'large' | 'small' }>`
    color: white;
    padding: 8px 16px;
    border-radius: 36px;
    background: #745CFF;
    border: unset;
    box-shadow: 0 8px 16px 0 rgba(22, 0, 163, 0.12);
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.15px;
    ${({ $size }) => $size === 'large' && css`
        padding: 20px 32px;
        border-radius: 36px;
        box-shadow: 0 24px 48px 0 rgba(22, 0, 163, 0.20);
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.18px;
    `};

    //Mobile
    ${down('lg')} {
        ${({ $size }) => $size === 'large' && css`
            padding: 16px 28px;
            border-radius: 36px;
            box-shadow: 0 24px 48px 0 rgba(22, 0, 163, 0.20);
            font-size: 17px;
            line-height: 24px;
            letter-spacing: 0.17px;
        `};
    }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const Header = styled.div`
    max-width: 1081px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 16px;
    margin: 0 auto;
    &:after {
        left: 0;
        top: 80px;
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed #C4DDE8;
    };
  
    //Mobile
    ${down('lg')} {
        width: 100%;
        max-width: unset;
        padding: 22px;
    }
`;

export const Content = styled.div`
    max-width: 1081px;
    padding: 192px 24px 112px 24px;
    margin: -80px auto 0 auto;
    background: url(${topLineVerticalImg});

    //Mobile
    ${down('lg')} {
        margin: -80px 16px 0 16px;
        padding: 128px 8px 72px 8px;
        max-width: unset;
        background: url(${mobileLineVerticalImg});
        background-size: 100%;
    }
`;

export const Footer = styled.div`
    background: linear-gradient(180deg, #F0F5FA 0%, rgba(246, 249, 252, 0.00) 50%), url(${backgroundColorImg}), url(${bottomLineHorizontalImg});
    background-repeat: no-repeat;
    background-size: auto 100%, auto 100%, auto;
    border-top: 1px dashed #C4DDE8;

    //Mobile
    ${down('lg')} {
        background: linear-gradient(180deg, #F0F5FA 0%, rgba(246, 249, 252, 0.00) 50%), url(${mobileLineHorizontalImg});
    }
`;

export const FooterInner = styled.div`
    max-width: 1081px;
    margin: 0 auto;
    padding: 112px 16px;
    background: url(${bottomLineVerticalImg});
    background-repeat: no-repeat;
    background-size: 100%;
  
    //Mobile
    ${down('lg')} {
        margin: 0 16px;
        padding: 72px 8px 72px 8px;
        background: url(${mobileLineVerticalImg});
        background-size: 100%;
    }
`;

export const Columns = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 42px;
    ${Paragraph} {
        margin: 0 0 12px 0;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.2px;
        > img {
            margin: 0 0 -6px 4px;
        }
    };
  
    //Mobile
    ${down('lg')} {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }
`;