import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    ${normalize()}
    body {
        font-family: 'Inter', sans-serif;
        -webkit-font-smoothing: antialiased;
    }
    * {
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
    }
`;