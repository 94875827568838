import * as SC from './styles';
import logoImg from '../../assets/logo.svg';
import flagImg from '../../assets/flag.svg';

const mailAddress = 'admin@apyflow.com';

export const HomePage = () => {
    const onClickHeader = (event: any) => {
        event?.preventDefault();
        window.location.href = `mailto:${mailAddress}`;
    };
    return (
        <SC.Container>
            <SC.Header>
                <img src={logoImg} />
                <SC.Button $size="small" onClick={onClickHeader}>
                    Connect with us
                </SC.Button>
            </SC.Header>
            <SC.Content>
                <SC.H1>Product development services for your existing and future projects</SC.H1>
                <SC.Paragraph>
                    Apyflow specializes in crafting captivating software and applications with optimum
                    usability and immersive aesthetics. <span>Our experienced team will help you develop engaging products and
                    user-centric solutions at any stage of your project's lifecycle.</span>
                </SC.Paragraph>
                <SC.Button $size="large" onClick={onClickHeader}>
                    Connect with us
                </SC.Button>
            </SC.Content>
            <SC.Footer>
                <SC.FooterInner>
                    <SC.H2>
                        Got a project?
                    </SC.H2>
                    <SC.Columns>
                        <div>
                            <SC.H3>Let’s talk</SC.H3>
                            <SC.Paragraph>
                                Just drop us a line and we will contact you in a day.
                            </SC.Paragraph>
                        </div>
                        <div>
                            <SC.H3>Contacts</SC.H3>
                            <SC.Paragraph onClick={onClickHeader}>
                                <a onClick={onClickHeader} href="/">{mailAddress}</a>
                            </SC.Paragraph>
                        </div>
                        <div>
                            <SC.H3>Headquaters</SC.H3>
                            <SC.Paragraph>
                                Dubai, United Arab Emirates <img src={flagImg} /><br />
                                Unit No: 3O-01-3821, Jewellery & Gemplex 3<br />
                                Plot No: DMCC-PH2-J&GPlexS, Jewellery & Gemplex
                            </SC.Paragraph>
                        </div>
                    </SC.Columns>
                </SC.FooterInner>
            </SC.Footer>
        </SC.Container>
    );
};